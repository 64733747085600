import React, {useState, useEffect} from 'react'; 
import { Outlet } from 'react-router-dom';
import { getScripts } from '../../../../services/met/Service';
import { useDispatch } from 'react-redux';



const MemberConnectAgentLayout = () => {
  
  // Setting calling agent: It will have only two possible values as of now: 1. mock_call (normal call) 2. webex
  const [callingAgent, setCallingAgent] = useState("mock_call");
  const [isLoading, setIsLoading] = useState(false);
  const [isAddMemberAllowedInCalingPopup, setIsAddMemberAllowedInCalingPopup] = useState(false);
  const [isWebexDeviceReady, setIsWebexDeviceReady] = useState(false);

  const dispatch  = useDispatch(); 

  useEffect(() => { 
    loadScripts(); 
  }, []); 

  // fetch calling agent 
  function loadScripts() {
    setIsLoading(true); 
    setIsWebexDeviceReady(true);
    let response;
    
       getScripts().then((response) => { 
        if (response.data.code === 200) {
          setCallingAgent(response.data?.data?.callingAgent);
          setIsAddMemberAllowedInCalingPopup(response.data?.data?.addMemberEnable);
          setIsWebexDeviceReady(false);
        }
      }).
      catch((e)  =>{
        setIsWebexDeviceReady(false);
      }).finally(() => { 
        setIsLoading(false); 
      })
  }

  
  

  return (
    <>
       <Outlet context={{isLoading:isLoading, callingAgent: callingAgent}} />
    </>
  )
}

export default MemberConnectAgentLayout;