import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { CustomLoader, ModalBoxButton } from '../../utils/CommonLibrary';
import DatePicker from "react-datepicker";
import './CareNavigatorAppointmentsDetails.css';
import { fetchAppointment, saveAppointment,fetchCareUserList } from '../../../services/medengage/Service';
import { Modal } from "react-bootstrap";
import {setMinutes, setHours} from "date-fns";
import CalendarDatePicker from '../CalendarDatePicker';
import moment from "moment";
import { decryptData } from '../../utils/Utils';

const CareNavigatorAppointmentsTable = () => {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModalNew, setShowModalNew] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalClass, setModalClass] = useState('md');
    const [modalBody, setModalBody] = useState('');
    const [careList, setCareList] = useState('');
    const [currentCareId, setCurrentCareId] = useState(0);
    const [memberName, setMemberName] = useState('');
    const [passCallId, setPassCallId] = useState(0);

    const handleCloseNew = () =>{
        setShowModal(false);
        fetchAppointmentData();
    }

    const handleClose = () =>{
        setShowModalNew(false);
    }

    const getNextWeekRange = () => {
        const end = new Date();
        const start = new Date();
        // end.setDate(end.getDate() + 7);
        return [start, end];
    };

    const [dateStartNew, setDateStartNew] = useState();

    useEffect(() => {
        fetchAppointmentData();
    }, [startDate,endDate]);

    const fetchAppointmentData = async () => {
        setLoading(true);   
        fetchAppointment(formatDateYmd(startDate),formatDateYmd(endDate))
            .then((response) => {
                setData(response.data.data.users);
                setLoading(false);
            });
    };

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const transferAppointmentSave = async () => {
        
        const currentDate = new Date();
        if (dateStartNew && dateStartNew < currentDate) {
            setModalBody('Select future date and time.');
            setModalTitle('Alert');
            setShowModal(true);
            return false;
        }
        setLoading(true);
        saveAppointment(passCallId,formatDateTime(dateStartNew),currentCareId)
            .then((response) => {
                if(response.data.code == 200){
                    const status = response.data.data.status;
                    setModalBody(response.data.data.msg);
                    setShowModalNew(false);
                    setShowModal(true);
                    if(status == 1){
                        setModalTitle('Success');
                    }else{
                        setModalTitle('Alert');
                    }
                    setLoading(false);
                }
        });
    };

    function formatDateYmd(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const formatDateTime = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };


    const openListModal = (callId,careNavigatorId,memberFullName) =>{
        setLoading(true);
        try{
            fetchCareUserList()
            .then((response) => {
                setDateStartNew('')
                setCareList(response.data.data.users);
                setCurrentCareId(careNavigatorId);
                setMemberName(memberFullName);
                setShowModalNew(true);
                setPassCallId(callId)
                setLoading(false);
            });
        }catch(error) {
            console.error('Error=', error);
        }
    }

    const columns = [
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership ID',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Original CareNavigator',
            selector: row => row.careName,
            sortable: true,
        },
        {
            name: 'Appointment Date & Time',
            selector: row => row.dateScheduled,
            sortable: true,
        },
        {
            name: 'Appointment Status',
            selector: row => row.callCurrentStatus,
            sortable: true,
            cell: row => (
                <span style={{ color: row.callCurrentStatus === 'Completed' ? '#0f9a15' : row.callCurrentStatus === 'Scheduled' ? '#e58d04' : '#FF0000' }}>
                    {row.callCurrentStatus}
                </span>
            ),
        },
        {
            name: 'Action',
            selector: row => row.callId,
            sortable: true,
            cell: row => (
                <div>
                    {row.callCurrentStatus != 'Cancelled' && 
                        <a onClick={(e) => openListModal(row.callId,row.careNavigatorId,row.memberName)} style={{ fontSize: '20px',cursor:"pointer" }}><i className="bi bi-pen"></i></a>
                    }
                </div>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery =
            (typeof user.memberName === 'string' && user.memberName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (typeof user.membershipNo === 'string' && user.membershipNo.toString().includes(searchQuery));
    
        const matchesStatus = selectedStatus === 'All' || user.callCurrentStatus === selectedStatus;
    
        return matchesSearchQuery && matchesStatus;
    });
    
    const handleChange = (e) => {
        setCurrentCareId(e.target.value);
    };  

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Member ID or Member Name"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">All Appointments</option>
                            <option value="Completed">Completed</option>
                            <option value="Scheduled">Scheduled</option>
                            <option value="Missed">Missed</option>
                            <option value="Cancelled">Cancelled</option>
                            
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-4 px-1'>
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                customStyles={customStyles}
            />

            {loading && <CustomLoader />}

            {showModal?<ModalBoxButton handleClose={handleCloseNew} showModaln={showModal} titlen={modalTitle} bodyn={modalBody} type={modalClass}  button={undefined}  hideButton={handleCloseNew}/>:null}

            <Modal dialogClassName="modal-md modal-dialog-centered" show={showModalNew} onHide={handleClose} id="showListDataTable" backdrop="static" keyboard={false}>
                <div className="modal-content">
                        <div className="modal-header">
                            
                            <h1 className="modal-title">Edit Appointment For {memberName}</h1>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handleClose}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 mb-3 px-3">
                                    <label className="form-label">Appointment Time</label>
                                    <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                    <DatePicker
                                        selectsRange={false}
                                        selected={dateStartNew}
                                        onChange={(date) => setDateStartNew(date)}
                                        showTimeSelect
                                        timeFormat="h:mm aa"
                                        timeIntervals={10}
                                        dateFormat="dd MMMM yyyy, h:mm aa"
                                        minDate={new Date()}
                                        minTime={dateStartNew > new Date() ? new Date(new Date().setHours(0,0,0,0)) : new Date()}
                                        maxTime={new Date().setHours(23, 59, 59)}
                                        placeholderText="Select Date and Time"
                                        className="form-control input-field-ui"
                                        isClearable={false}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                        <i className='bx bx-calendar'
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                pointerEvents: 'none',
                                                fontSize: '20px',
                                                color: '#666'
                                            }}></i>
                                            </div>

                                            
                                </div>
                                <div className="col-md-12 mb-3 px-3">
                                    <label className="form-label">Care Navigator</label>
                                    <select className='form-select form-control input-field-ui' value={currentCareId} onChange={handleChange}>
                                        {careList.length > 0 && careList.map(option => (
                                            <option key={option.membershipNo} value={option.membershipNo}>
                                                {option.careName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                        <div className="col-md-12 px-3 my-2">
                                <button
                                    type="submit"
                                    className="btn btn-primary program-save-btn me-2"
                                    onClick={(e) => transferAppointmentSave()}
                                >
                                    Save
                                </button>
                                <button type="submit" className="btn btn-primary clear-btn" onClick={handleClose} aria-label="Close">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
        </>
    );
}

export default CareNavigatorAppointmentsTable;
