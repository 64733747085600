import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';

// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const StatusDistribution = (props) => {
  // Filter out zero values from the data
  const defaultData = [
    { name: 'Pending Review', y: props.data.PendingReview, color: '#145EC5' },
    { name: 'Closed', y: props.data.Closed, color: '#3780B8' },
    { name: 'Reopened', y: props.data.Reopened, color: '#37A6E4' },
    { name: 'In Progress', y: props.data.InProgress, color: '#93C9FE' },
    { name: 'Rejected', y: props.data.Rejected, color: '#FF6B6B' },
    { name: 'Need More Information', y: props.data.NeedMoreInformation, color: '#4ECDC4' },
    { name: 'Pending Acceptance', y: props.data.PendingAcceptance, color: '#FFD93D' },
    { name: 'Not an Issue', y: props.data.NotanIssue, color: '#95A5A6' },
    { name: 'Duplicate', y: props.data.Duplicate, color: '#BDC3C7' },
    { name: 'Out Of Scope', y: props.data.OutOfScope, color: '#7F8C8D' }, 
    { name: 'Bupa Dependancy', y: props.data.BupaDependancy, color: '#7F8C8D' },
    { name: 'Dependancy Resolved', y: props.data.DependancyResolved, color: '#7F8C8D' }
  ].filter(item => item.y > 0); // Only keep items with non-zero values

  const pieData = props.series || defaultData;

  const pieOptions = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
          enabled: true,
          alpha: 70,
          beta: 0,
          depth: 50,
          viewDistance: 0
      },
    },
    title: "    ",
    subtitle: null,
    tooltip: {
       pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          distance: 5,
          alignTo: 'toPlotEdges'
        },
        showInLegend: true
      }
    },
    series: [{
      name: 'Observation Type',
      data: pieData
    }],
    credits: {
      enabled: false
    },
    exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              enabled: false
            }
          }
        },
    legend: {
      enabled: true,
      labelFormatter: function() {
        return this.y > 0 ? this.name : null;
      }
    }
  };

  return (
    <div>
      {pieData?.length > 0 && pieData.some(item => item.y > 0) ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={pieOptions}
        />
      ) : (
        <div className="emty-pie">
          <h5 className="emty-title-graph">There are no data to display</h5>
        </div>
      )}
    </div>
  );
};

export default StatusDistribution;
