import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { formatDate } from '../../utils/CommonLibrary';

// Initialize exporting module
exporting(Highcharts);

const BarGraph = ({ graphData }) => {


    const [loadingCount, setLoadingCount] = useState(0);

    const { weekBooked, callsBooked } = graphData;

    const yAxisJson = {
        title: {
            text: null
        }
    };

    const tooltipJson = {
        shared: true,
        valueSuffix: ''
    };

    const seriesData = [{
        name: 'Calls Made',
        data: callsBooked,
        color: '#5CA2D7'
    },];

    const optionsMCI2 = {
        chart: {
            type: 'column',
            height: 200,
            spacing: [20, 0, 0, 0],
            options3d: {
                enabled: true,
                alpha: 0,
                beta: 10,
                depth: 50,
                viewDistance: 25
            }
        },
        title: {
            text: null,
            align: 'left',
            style: {
                fontSize: '20px',
                fontWeight: '600',
                color: '#333',
                fontFamily: "IBM Plex Sans, sans-serif"
            }
        },
        xAxis: {
            categories: weekBooked,
            gridLineWidth: 0
        },
        yAxis: yAxisJson,
        tooltip: tooltipJson,
        plotOptions: {
            column: {
                borderRadius: 5,
                pointPadding: 0.2,
                groupPadding: 0.1,
                stacking: null,
                dataLabels: {
                    enabled: true,
                    style: {
                        textOutline: 'none'
                    }
                }
            }
        },
        series: seriesData,
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        legend: {
            enabled: false
        }
    };

    return (
        <>
            {weekBooked?.length > 0 ? (
                <div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsMCI2}
                    />
                </div>
            ) : (
                <div className="emty-column">
                    <h5 className="emty-title-graph">There are no data to display</h5>
                </div>
            )}
        </>
    );
};

export default BarGraph;
