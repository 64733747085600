import CryptoJS from 'crypto-js';


export const encryptData = (text) => {
    try {
        const key_hex = process.env.REACT_APP_KEY;
        const iv_hex = process.env.REACT_APP_IV;

        const key = CryptoJS.enc.Hex.parse(key_hex);
        const iv = CryptoJS.enc.Hex.parse(iv_hex);

        const encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    } catch (error) {
        console.error("encryptData Exception = ", error);
        return false;
    }
};

export const decryptData = (encryptedText) => {
    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
    const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
};


export function capitalizeFirstLetter(str){ 
    if (!str) return str; // Return if the string is empty
     return str.charAt(0).toUpperCase() + str.slice(1);
}


export const replaceUnderScoreWithSpace = (str="") => {
    return str.split('_').map(word => capitalizeFirstLetter(word)).join(' ')
}


export function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();
  
    let age = today.getFullYear() - birthDate.getFullYear();
  
    // Check if the birthday has not yet occurred this year
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
  
    return age;
}