import React from 'react';
import ContentProgramPerformanceDetail from './componenents/ContentProgramPerformance/ContentProgramPerformanceDetail';
import './componenents/ContentProgramPerformance.css';

function ProgramPerformancePage() {

  return (
    <>
      <section className='ReportsPrograms'>
        <ContentProgramPerformanceDetail />

      </section>
    </>
  );
}

export default ProgramPerformancePage;
