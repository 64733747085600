import React, {useEffect, useState } from "react";
import moment from "moment";
import { decryptData, formatDate } from "../utils/CommonLibrary";
import TelehealthInsightsTable from "./TelehealthInsightsTable";
import CalendarDatePicker from "../../componenents/CalendarDatePicker";
import { admin_telehealth_insights, telehealth_status_graph} from "../../../services/met/Service";
import { LoaderLoader } from "../../utils/CommonLibrary";



const TelehealthInsights = () => {
  const storedDates = localStorage.getItem("selectedDates")
    ? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
    : "";

  const [startDate, setStartDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
  );
  const [endDate, setEndDate] = useState(
    storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
  );

  const [isLoading, setIsLoading] = useState(false); 
  const [communicationStats, setCommunicationStats]  =  useState({
    totalTeleHealthBookings: 0, 
    membersReached: 0, 
    agentCount: 0
  });   
  


  useEffect(() => {
    fetchTeleHealthStats(); 
  }, []); 

  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };



  function fetchTeleHealthStats(){  
    setIsLoading(true); 
    admin_telehealth_insights({startDate: formatDate(startDate), endDate: formatDate(endDate)}).then((response) => { 
        if(response.data.code === 200){ 
            setCommunicationStats({
                totalTeleHealthBookings: response.data.data?.teleHealthBookingCount, 
                membersReached: response.data.data?.memberReachCount, 
                agentCount: response.data.data?.agentCount
            }); 
        }
    }).catch((e) => {   

    }).finally(() => { 
        setIsLoading(false); 
    })
  }

  

  return (
    <div className="ContentUserManagement"> 

               <LoaderLoader isLoading={isLoading} />
                <div className="container-fluid">
                    <div className="row align-items-center pb-3 sticky-top-bar">
                        <div className="col-md">
                            <h4 className="mb-0 content-main-title">Telehealth Insights</h4>
                        </div>
                        <div className="col-md-3">
                            <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                        </div>
                    </div>

                    <div className="row align-items-center pb-3">
                        <div className='reports-programs'>
                            <div className=" row align-items-center">
                                <div className="col-md-auto">
                                    <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/so7jq/qhjo3szoyh.png" alt="Invited Members icon" style={{ height: "140px" }} />
                                </div>
                                <div className="col-md">
                                    <p className="mb-0">The TeleHealth Insights dashboard provides an overview of TeleHealth bookings and agent performance. It helps administrators track member engagement, call activity, and booking trends over a selected date range. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center pb-3">
                        <div className='comntent-UserManagement mb-3'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="">
                                        <TelehealthInsightsTable 
                                            startDate={startDate} 
                                            endDate={endDate}
                                            setIsLoading={setIsLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  );
};

export default TelehealthInsights;
